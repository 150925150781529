<template>
  <div class="c-search-bar" v-if="data.searchType">
    <input class="search-input" :placeholder="data.placeholder" />
    <img class="search-icon" src="@/assets/myMusic/icon_search.svg" />
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SearchBar',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
});
</script>
<style lang="scss" scoped>
.c-search-bar {
  @include padding(0 25px);
  @include inline-flex();
  height: 40px;
  width: 100%;
  max-width: 425px;
  background-color: $c-assist1;
  border-radius: 25px;
  margin-right: 0px;
}
.search-input {
  flex: 1;
  background-color: transparent;
  outline: transparent;
  border: 0px transparent;
  @include font-style($c-black, 16, normal, 0.8, 22px);

  &::placeholder {
    @include font-style($c-assist3, 16, normal, 0.8, 22px);
  }
}

.search-icon {
  flex: none;
  width: 20px;
  vertical-align: bottom;
}

@media screen and (min-width: $sm-pc) {
  .c-search-bar {
    height: 50px;
    width: 425px;
    margin-right: 38px;
  }
}
</style>
