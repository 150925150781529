<template>
  <div class="c-my-music-function-bar">
    <div class="c-my-music-function-bar__content g-content">
      <div class="c-my-music-function-bar__content__left">
        <search-bar :data="config.search" />

        <div class="show-sm-pc">
          <my-music-info :data="config.info" />
        </div>
      </div>
      <div class="c-my-music-function-bar__content__right">
        <list-filter :data="config.listFilter" />
        <function-icons :data="config.functionIcons" />
        <add-playlists-btn :data="config.addPlaylistsBtn" />
        <!-- <my-music-list /> -->
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import SearchBar from '@/components/MyMusic/SearchBar.vue';
import MyMusicInfo from '@/components/MyMusic/MyMusicInfo.vue';
import ListFilter from '@/components/MyMusic/ListFilter.vue';
import FunctionIcons from '@/components/MyMusic/FunctionIcons.vue';
import AddPlaylistsBtn from '@/components/Global/AddPlaylistsBtn.vue';
// import MyMusicList from '@/components/MyMusic/MyMusicList/index.vue';

export default defineComponent({
  name: 'MyMusicFunctionBar',
  components: {
    SearchBar,
    MyMusicInfo,
    ListFilter,
    FunctionIcons,
    AddPlaylistsBtn,
    // MyMusicList,
  },
  props: {
    config: {
      type: Object,
      default: () => ({}),
    },
  },
});
</script>
<style lang="scss" scoped>
.c-my-music-function-bar {
  background-color: $c-white;
  @include padding(0 0 20px);

  &__content {
    @include flex(space-between);

    &__left {
      @include flex(flex-start);
      width: 100%;
    }

    &__right {
      display: none;
    }
  }
}

.show-sm-pc {
  display: none;
}

@media screen and (min-width: $sm-pc) {
  .c-my-music-function-bar {
    @include padding(0 0 35px);

    &__content {
      &__left {
        width: auto;
      }

      &__right {
        @include flex(flex-end);
      }
    }
  }

  .show-sm-pc {
    display: block;
  }

}
</style>
