<template>
  <p class="c-modify-date">
    {{ data.modifyDate}}
  </p>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ModifyDate',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },

  },
});
</script>
<style lang="scss" scoped>
.c-modify-date {
  @include font-style($c-assist4, 14, 600, 0, 26px);

}
</style>
