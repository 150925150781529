<template>
  <div class="c-my-music-info" v-if="data.list.length">
    <ul class="info-list">
      <li class="info-list__item" v-for="item of data.list" :key="item.label">
        <div class="data-box">
          <p class="data-box__label">{{ item.label }}</p>
          <p class="data-box__data">{{ item.data }}</p>
        </div>
      </li>
    </ul>
    <template >

    </template>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'MyMusicInfo',
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },

});
</script>
<style lang="scss" scoped>
.info-list {
  @include inline-flex();

  &__item {

    & + & {
      margin-left: 20px;
    }
  }
}

.data-box {
  @include flex(flex-start, flex-start, column);
  min-width: 40px;

  &__label {
    @include font-style($c-assist3, 12, normal, 0.6px, 15px);
  }

  &__data {
    margin-top: 4px;
    @include font-style($c-assist6, 16, bold, 0.8px, 22px);
  }
}
</style>
