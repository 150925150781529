<template>
  <div class="c-list-filter" v-if="data.checkboxs.length">
    <n-checkbox-group v-model:value="showList">
      <n-space item-style="display: flex;">
        <tooltip :label="tooltipType[item.label]" v-for="item of data.checkboxs" :key="item.value">
          <n-checkbox :value="item.value">

          <img class="icon" v-if="item.label === 'Waves'" src="@/assets/searchResults/search_icon_wave.svg"/>
          <img class="icon" v-if="item.label === 'Description'" src="@/assets/searchResults/search_icon_description.svg"/>
          <img class="icon" v-if="item.label === 'Tags'" src="@/assets/searchResults/search_icon_tag.svg"/>
          </n-checkbox>
        </tooltip>
      </n-space>
    </n-checkbox-group>
  </div>
</template>
<script>
import { defineComponent, watch, ref } from 'vue';
import { NSpace, NCheckbox, NCheckboxGroup } from 'naive-ui';
import { useRoute } from 'vue-router';
import { useMyMusicStore } from '@/store/myMusic';
import Tooltip from '@/components/Global/Tooltip.vue';

export default defineComponent({
  name: 'ListFilter',
  components: {
    NSpace,
    NCheckbox,
    NCheckboxGroup,
    Tooltip,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const showList = ref([]);
    const route = useRoute();
    const myMusicStore = useMyMusicStore();

    const tooltipType = {
      Waves: '波幅',
      Description: '描述',
      Tags: '標籤',
    };

    watch(showList, () => {
      if (route.name === 'search') {
        myMusicStore.setListFilter(`search-${route.query.tab}`, showList.value);
      }
      myMusicStore.setListFilter(route.name, showList.value);
    });

    return {
      showList,
      tooltipType,
    };
  },
});
</script>
<style lang="scss" scoped>
.c-list-filter {
  margin-right: 12px;
}

.icon {
  width: 20px;
}
</style>
